var arrayAn = {
    '0': true,
    '1': false,
    '2': false,
    '3': false,
    '4': false,
    '5': false,
    '6': false,
    '7': false,
    '8': false,
    '9': false,
    '10': false,
    '11': false,
    '12': false,
    '13': false,
    '14': false,
    '15': false,
    '16': false,
    '17': false,
    '18': false,
    '19': false,
    '20': false,
    '21': false,
    '22': false,
    '23': false,
    '24': false,
    '25': false,
    '26': false,
    '27': false,
    '28': false,
    '29': false,
    '30': false
}

//Genform
document.addEventListener('genform-progress', event => {

    //--Sidebar Steps
    var numstep = event.detail.step;

    if ($(".formulaire.formlivewire")[0]) {
        //Compter le nbre total d'étapes
        var json = event.detail.stepsTitle;
        var countSteps = $("div.data-steps-form[data-steps]").attr('count-steps');

        if (typeof json !== 'undefined') {
            var objet = JSON.parse(JSON.stringify(json));

            if (numstep >= countSteps) {
                // Recommande ON
                // reset sidebar
                $(".formulaire .timelineVerticale").html('');
                var i;
                for (i = 0; i < objet.length; ++i) {
                    var numberStep = i + parseInt(countSteps) + 1;
                    if (i == 0) {
                        $(".formulaire .timelineVerticale").append("<div class='prev step step-active step-" + numberStep + "'><div><div class='circle'>" + numberStep + "</div></div><div><div class='title'>" + objet[i] + "</div></div></div>");
                    } else {
                        $(".formulaire .timelineVerticale").append("<div class='step step-" + numberStep + "'><div><div class='circle'>" + numberStep + "</div></div><div><div class='title'>" + objet[i] + "</div></div></div>");
                    }
                }
            } else {
                // Recommande OFF
                if ($(".formulaire .timelineVerticale .step-1 .title").text() != objet[0]) {
                    // reset sidebar
                    $(".formulaire .timelineVerticale").html('');

                    var i;
                    for (i = 0; i < objet.length; ++i) {
                        var passed = "";
                        if(i < numstep){
                            var passed = "step-active";
                        }
                        if (i == 0) {
                            $(".formulaire .timelineVerticale").append("<div class='"+passed+" prev step step-" + (i + 1) + "'><div><div class='circle'>" + (i + 1) + "</div></div><div><div class='title'>" + objet[i] + "</div></div></div>");
                        } else {
                            $(".formulaire .timelineVerticale").append("<div class='"+passed+" step step-" + (i + 1) + "'><div><div class='circle'>" + (i + 1) + "</div></div><div><div class='title'>" + objet[i] + "</div></div></div>");
                        }
                    }
                }
            }
        }
    }

    $( ".formulaire .timelineVerticale .step-"+numstep ).removeClass( "step-active" );
    $( ".formulaire .timelineVerticale div" ).removeClass( "passed" );
    $( ".formulaire .timelineHorizontale .step-"+numstep ).attr( "aria-expanded","false" );

    for (var i=1; i<=numstep; i++) {
        $( ".formulaire .timelineVerticale .step-"+i ).addClass( "step-active" );
        $( ".formulaire .timelineHorizontale .step-"+i ).attr( "aria-expanded","true" );
    }

    $( ".formulaire .timelineVerticale .step-"+(numstep+1) ).addClass( "step-active" );

    //--Display tinymce if necessary
    var tinyInput = tinymce.init({
        selector:'textarea.description',
        entity_encoding:'raw',
        valid_elements : '*[*]',
        menubar: false,
        height: 500,
        forced_root_block: false,
        branding: false,
        statusbar: false,
        setup: function (editor) {
            editor.on('init change', function () {
                editor.save();
            });
            editor.on('change', function (e) {
                changeDataBody(editor.getContent());
            });
        },
    });

    //--Display tinymce if necessary
    var tinyInput = tinymce.init({
        selector:'textarea.object',
        entity_encoding:'raw',
        menubar: false,
        toolbar : '',
        height: 150,
        forced_root_block: false,
        branding: false,
        statusbar: false,
        setup: function (editor) {
            editor.on('init change', function () {
                editor.save();
            });
            editor.on('change', function (e) {
                changeDataObject(editor.getContent());
            });
        },
    });

    selectChange();

    var titleProcedure = $('.demarchetitle h1').text();
    var titleStep = '';
    var isRec= false;
    if(objet != ''){
        titleStep = objet[numstep];
        if(titleStep === undefined){
            isRec = true;
            titleStep = objet[numstep - countSteps]; // recommande
        }
    }
    if(numstep > 0 && arrayAn[numstep] == false){
        // Si étape 2, on envoit l'étape 1 en même temps
        if(arrayAn[0] == false){
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'funnelDemarche',
                'nomDemarche': titleProcedure,
                'etapeDemarche': 1+' - '+$(".formulaire .timelineVerticale .step-1 .title").text()
            });
            console.log(1+' - '+$(".formulaire .timelineVerticale .step-1 .title").text());
            arrayAn[0] = true;
        }

        window.dataLayer = window.dataLayer || [];
        arrayAn[numstep] = true;
        if(isRec){
            if(event.detail.maileva == true){
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'lrarDemarche',
                    'lrarNomDemarche': titleProcedure,
                    'lrarPiecesJointes': event.detail.countFilesMaileva
                });
            } else {
                window.dataLayer.push({
                    'event': 'funnelDemarche',
                    'nomDemarche': titleProcedure,
                    'etapeDemarche': 'LRAR - '+titleStep
                });
            }
        } else {
            window.dataLayer.push({
                'event': 'funnelDemarche',
                'nomDemarche': titleProcedure,
                'etapeDemarche': (parseInt(numstep)+1)+' - '+titleStep
            });
        }
    }
});

document.addEventListener('genform-render', event => {
    //--Refresh tooltip foreach steps
    loadTooltip();

    $(".selectpicker-solo").select2({
        'placeholder': "Choisissez...",
        'allowClear': true
    });

    $(".selectpicker-multi").select2({
        'placeholder': "Choisissez...",
        'multiple':true,
        'allowClear': true
    });

    $('.selectpicker-multi, .selectpicker-solo').on('change', function (e) {
        var name = 'datas.'+$(this).attr('data-step')+'.'+$(this).attr('id');
        setDataLive(name, $(this).val());
    });

    $(".datepicker").datepicker({
        dateFormat: 'dd/mm/yy',
        changeMonth: true,
        changeYear: true,
        yearRange: '1910:2080',
        monthNames: [ "Janvier","Février","Mars","Avril","Mai","Juin", "Juillet","Août","Septembre","Octobre","Novembre","Décembre" ],
        monthNamesShort: [ "Jan", "Fev", "Mar", "Avr", "Mai", "Jun", "Jul", "Aou", "Sep", "Oct", "Nov", "Dec" ],
        dayNames: [ "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi" ],
        dayNamesShort: [ "Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam" ],
        dayNamesMin: [ "Di","Lu","Ma","Me","Je","Ve","Sa" ],
        firstDay: 1,
        prevText: "<",
        nextText: ">"
    });

    $(".datepicker-time").timepicker({
        timeFormat: "HH:mm",
        dropdown: false,
        change: function(time) {
            var step = $('#'+$(this).attr('id')).attr('data-step');
            var name = 'datas.'+step+'.'+$(this).attr('id');
            // get access to this Timepicker instance
            var timepicker = $(this).timepicker();

            setDataLive(name, timepicker.format(time))
        }
    })

    // Autocomplete adress
    var inputs = $('.address_auto');

    $('input.address_auto').focus(function() {
        setAdresse(this);
    });

    searchAdresses(inputs);
});

document.addEventListener('genform-recommande',event=>{
    //--Display tinymce if necessary
    var tinyInput = tinymce.init({
        selector:'textarea.description',
        entity_encoding:'raw',
        valid_elements : '*[*]',
        menubar: false,
        height: 500,
        forced_root_block: false,
        branding: false,
        statusbar: false,
        setup: function (editor) {
            editor.on('init change', function () {
                editor.save();
            });
            editor.on('change', function (e) {
                changeDataBody(editor.getContent());
            });
        },
    });

    //--Display tinymce if necessary
    tinymce.init({
        selector:'textarea.object',
        entity_encoding:'raw',
        menubar: false,
        toolbar : '',
        height: 150,
        forced_root_block: false,
        branding: false,
        statusbar: false,
        setup: function (editor) {
            editor.on('init change', function () {
                editor.save();
            });
            editor.on('change', function (e) {
                changeDataObject(editor.getContent());
            });
        },
    });
})
